import "./widget.scss"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';




const Widget = ({type}) => {

    let data;

    //temporary data

    const amount=10000;
    const diff=20;

    switch(type){
        case "user":
            data={
                title:"USERS",
                isMoney:false,
                link:"View all users",
                icon: 

                    <PersonOutlineIcon className="icon" 
                    style={{
                        backgroundColor: "rgba(218,165,32,0.2)",
                        color:"crimson", 
                }}
                />
                
            };
            break;
            case "order":
            data={
                title:"ORDERS",
                isMoney:false,
                link:"View all orders",
                icon: 

                    <ShoppingCartOutlinedIcon className="icon"
                    
                    style={{
                        backgroundColor: "rgba(218,165,32,0.2)",
                        color:"goldenrod", 
                }}
                    />,
                
            };
            break;
            case "earning":
            data={
                title:"EARNINGS",
                isMoney:true,
                link:"View net earnings",
                icon: 

                    <MonetizationOnOutlinedIcon className="icon"
                    style={{
                        backgroundColor: "rgba(218,165,32,0.2)",
                        color:"green", 
                }}
                    />,
                
            };
            break;

            case "balance":
            data={
                title:"BALANCE",
                isMoney:true,
                link:"See balance details",
                icon: 

                    <AccountBalanceWalletOutlinedIcon className="icon"
                       
                    style={{
                        backgroundColor: "rgba(218,165,32,0.2)",
                        color:"purple", 
                }}
                       
                    />,
                
            };
            break;
            default:
                break;

    }
  return (
    <div className="widget">
        <div className="left">
            <span className="title">{data.title}</span>
            <span className="counter">{data.isMoney && "KES"}{amount}
            </span>
            <span className="link">{data.link}</span>

        </div>
        <div className="right">
            <div className="percentage positive">
                <KeyboardArrowUpIcon/>
                {diff}%</div>
        </div>
          {data.icon}
    </div>
  )
}

export default Widget