import "./single.scss"
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import Chart from "../../components/chart/Chart"
import  List  from "../../components/table/Table"
const Single = () => {
  return (
    <div className="single">
      <Sidebar/>
       <div className="singleContainer">
         <Navbar/>
         <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
             <div className="title">Information</div>
              
              <div className="item">
              <img 
              src="https://images.unsplash.com/photo-1611432579699-484f7990b127?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80" 
              alt="someImage" 
              className="itemImg" />
                 <div className="details">
                   <h1 className="itemTitle">Jane Mali</h1>
                    <div className="detailItem">
                      <span className="itemKey">Email:</span>
                      <span className="itemValue">janemali@gmail.com</span>
                    </div>

                    <div className="detailItem">
                      <span className="itemKey">Phone:</span>
                      <span className="itemValue">+254-712 345 678</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Address:</span>
                      <span className="itemValue">Azure Towers, off Raphta RD, Nairobi</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Country:</span>
                      <span className="itemValue">Kenya</span>
                    </div>
                 </div>
              </div>

          </div>
          <div className="right">
            <Chart aspect={3/1} title="User Spending(Last 6 Months)"/>
          </div>
         </div>

         <div className="bottom">
         <div className="title">Last Transactions</div>
                <List/>
         </div>
       </div>
    </div>
  )
}

export default Single