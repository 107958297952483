import "./sidebar.scss"
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import StoreIcon from '@mui/icons-material/Store';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import PieChartIcon from '@mui/icons-material/PieChart';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsSystemDaydreamOutlinedIcon from '@mui/icons-material/SettingsSystemDaydreamOutlined';
import LoginIcon from '@mui/icons-material/Login';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import PortraitIcon from '@mui/icons-material/Portrait';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
const Sidebar = () => {
  const{dispatch} = useContext(DarkModeContext);

  return (
    <div className="sidebar">
     
       <div className="top">
        <Link to="/" style={{textDecoration:"none"}}>
        <span className="logo">HLadmin</span>
        </Link>
        </div>
          <hr/>
       <div className="center">
              <ul className="ul">
                <p className="title">MAIN<hr/></p>
            <Link to="/" style={{textDecoration:"none"}}>
                   <li>
                   <DashboardCustomizeIcon className="icon"/>
                    <span>Dashboard</span>
                   </li>
            </Link>
                <p className="title">LINKS<hr/></p>
              <Link to="/users" style={{textDecoration:"none"}}>
                   <li>
                    <PeopleOutlineIcon className="icon"/>
                    <span>Users</span>
                   </li> 
              </Link>
              <Link to="/products" style={{textDecoration:"none"}}>
                   <li>
                   <StoreIcon className="icon"/>
                    <span>Products</span>
                   </li>
              </Link>
                   <li>
                   <ReceiptLongIcon className="icon"/>
                    <span>Orders</span>
                   </li>
                   <li>
                   <DeliveryDiningIcon className="icon"/>
                    <span>Delivery</span>
                   </li>
                   <p className="title">USEFUL<hr/></p>
                   <li>
                   <PieChartIcon className="icon"/>
                    <span>Stats</span>
                   </li>
                   <li>
                   <NotificationsNoneIcon className="icon"/>
                    <span>Notifications</span>
                   </li>
                   <p className="title">SERVICE<hr/></p>
                   <li>
                   <SettingsSystemDaydreamOutlinedIcon className="icon"/>
                    <span>System Health</span>
                   </li>
                   <li>
                   <LoginIcon className="icon"/>
                    <span>Logs</span>
                   </li>
                   <li>
                   <SettingsApplicationsIcon className="icon"/>
                    <span>Settings</span>
                   </li>
                   <p className="title">USER<hr/></p>
                   <li>
                   <PortraitIcon className="icon"/>
                    <span>Profile</span>
                   </li>
                   <li>
                   <LogoutIcon className="icon"/>
                    <span>Logout</span>
                   </li>
              </ul>
       </div>
       <div className="bottom">
            <div className="colorOption" onClick={()=>dispatch({type:"LIGHT"})}></div>
            <div className="colorOption" onClick={()=>dispatch({type:"DARK"})}></div>
            
       </div>
    </div>
  )
}

export default Sidebar 