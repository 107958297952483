export const userColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "user",
      headerName: "User",
      width: 230,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img className="cellImg" src={params.row.img} alt="avatar" />
            {params.row.username}
          </div>
        );
      },
    },
    {
        field:"email", 
        headerName:"Email", 
        width:230,
    },
    {
        field:"age", 
        headerName:"DOB", 
        width:230,
    },
    {
        field:"status", 
        headerName:"Status", 
        width:160,
        renderCell:(params)=>{
            return(
                <div className={`cellWWithStatus ${params.row.status}`}>
                    {params.row.status}
                </div>
            )
        }
    },
];


//temporary data is here:
export const userRows =[
{
    id:1,
    username: "Snow",
    img: "https://images.pexels.com/photos/3746226/pexels-photo-3746226.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    status: "active",
    email: "1snow@yahoo.com",
    age: 33,
},

{
    id:2,
    username: "Jean",
    img: "https://images.unsplash.com/photo-1595211877493-41a4e5f236b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=415&q=80",
    status: "active",
    email: "jean@yahoo.com",
    age: 30,
},
{
    id:3,
    username: "Omondi",
    img: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    status: "active",
    email: "omosh@gmail.com",
    age: 38,
},
{
    id:4,
    username: "Jack",
    img: "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=461&q=80",
    status: "pending",
    email: "jack@live.com",
    age: 28,
},
{
    id:5,
    username: "Perez",
    img: "https://images.unsplash.com/photo-1554727242-741c14fa561c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    status: "passive",
    email: "perez@liko.com",
    age: 36,
},
{
    id:6,
    username: "Mbugua",
    img: "https://images.unsplash.com/photo-1540569014015-19a7be504e3a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80",
    status: "active",
    email: "mbugua@msn.com",
    age: 40,
},

{
    id:7,
    username: "Eriko",
    img: "https://images.unsplash.com/photo-1540569014015-19a7be504e3a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80",
    status: "pending",
    email: "eriko@msn.com",
    age: 23,
},
{
    id:8,
    username: "Chris",
    img: "https://images.unsplash.com/photo-1540569014015-19a7be504e3a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80",
    status: "active",
    email: "chris@lim.co.ke",
    age: 29,
},
{
    id:9,
    username: "Daniel",
    img: "https://images.unsplash.com/photo-1540569014015-19a7be504e3a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80",
    status: "passive",
    email: "daniel24@gmail.com",
    age: 40,
},

{
    id:10,
    username: "Nancy",
    img: "https://images.unsplash.com/photo-1540569014015-19a7be504e3a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80",
    status: "pending",
    email: "nancy@harstlogics.com",
    age: 37,
},
]